<template>
  <a-row>
    <a-col
      :xxl="8"
      :xl="9"
      :lg="12"
      :md="8"
      :xs="24"
    >
      <Aside>
        <div class="auth-side-content">
          <img
            :src="require('@/static/img/auth/topShape.png')"
            alt=""
            class="topShape"
          >
          <img
            :src="require('@/static/img/auth/bottomShape.png')"
            alt=""
            class="bottomShape"
          >
          <Content>
            <img
              :src="logoPath"
              :style="{ width: '150px' }"
              alt=""
            >
            <br>
            <br>
            <img
              class="auth-content-figure"
              :src="imagePath"
              style="width:500px"
              alt=""
            >
          </Content>
        </div>
      </Aside>
    </a-col>

    <a-col
      :xxl="16"
      :xl="15"
      :lg="12"
      :md="16"
      :xs="24"
    >
      <router-view />
    </a-col>
  </a-row>
</template>
<script>
import { Aside, Content } from './style'

const AuthLayout = {
  name: 'AuthLayout',
  components: { Aside, Content },
  computed: {
    imagePath() {
      if (window.location.host === 'cargo.tekrom.com') {
        return require('../img/tsoftshipment.png')
      } else {
        return require('../img/cargongshipment.png')
      }
    },
    logoPath() {
      if (window.location.host === 'cargo.tekrom.com') {
        return require('../img/tsoft-logo.png')
      } else {
        return require('../img/cargonglogo.png')
      }
    },
  },
}

export default AuthLayout
</script>


